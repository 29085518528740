<template>
  <Layout :title="$t('waterMark.title')">
    <div :class="prefixCls">
      <div
        :class="prefixCls + '__item'"
        @click="
          $router.push({
            name: 'watermarkAdd'
          })
        "
      >
        <img
          src="../assets/images/watermark-add@2x.png"
          width="120"
          height="120"
          :alt="$t('waterMark.tipsAlt')"
        />
        <p>{{ $t('waterMark.tipsAlt') }}</p>
      </div>
      <div
        :class="prefixCls + '__item'"
        @click="
          $router.push({
            name: 'watermarkDetection'
          })
        "
      >
        <img
          src="../assets/images/watermark-detection@2x.png"
          width="120"
          height="120"
          :alt="$t('waterMark.tipsDetect')"
        />
        <p>{{ $t('waterMark.tipsDetect') }}</p>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'

const prefixCls = 'copyright-page'
export default {
  name: 'Watermark',
  components: {
    Layout
  },
  data() {
    return {
      prefixCls
    }
  }
}
</script>

<style lang="scss">
$prefixCls: 'copyright-page';
.#{$prefixCls} {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 210px;
  &__item {
    text-align: center;
    cursor: pointer;
    height: 240px;
    &:hover {
      -webkit-animation: test 0.5s ease;
      animation: test 0.5s ease;
      animation-fill-mode: forwards;
    }
    &:first-child {
      margin-right: 300px;
    }
    img {
    }
    p {
      color: #5391f9;
      font-size: 24px;
    }
  }
}
@keyframes test {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
}
@-webkit-keyframes test {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
}
</style>
